import React from 'react'
import Navbar from "../Navbar"
import Footer from "../Footer/Footer"

const Index = ({children}) => {
  return (
    <>
    <Navbar />
      {children}
    <Footer />
    </>
  )
}

export default Index