import React from "react";
import { MyNotificationContainer } from "./style";
import { FaTimes } from "react-icons/fa";

const MyNotification = ({ message, onClick, backGroundColor, icon }) => {
  return (
    <MyNotificationContainer>
      <div className="my-message-box-custom" style={{}}>
        <div
          className="message-box-custom-inner"
          style={{
            background:
              "linear-gradient(0deg, rgba(52, 205, 119, 0.1), rgba(52, 205, 119, 0.1)), #FFFFFF",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "max-content",
          }}
        >
          <div
            style={{
              marginRight: "12px",
              width: "40px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: backGroundColor,
              borderRadius: "50%",
            }}
            className="notification-icon"
          >
            {icon}
          </div>
          <div>
            <h5
              style={{
                fontFamily: "human_sans_400",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#14283C",
                margin: " unset",
              }}
              dangerouslySetInnerHTML={{ __html: message }}
            ></h5>
          </div>
          <button
            style={{
              marginLeft: "30px",
              marginRight: "24px",
              width: "13px",
              height: "20px",
              background: "transparent",
              border: "none",
              color: "#14283C",
              fontWeight: "400",
              fontSize: "20px",
              cursor: "pointer",
              opacity:0.3
            }}
            onClick={onClick}
            className="close-notification-div"
          >
           <FaTimes />
          </button>
        </div>
      </div>
    </MyNotificationContainer>
  );
};

export default MyNotification;
