import React from "react";
import {
  FooterContainer,
  FooterTopContent,
  FooterBottomContent,
} from "./style";
import {
  LinkedInLogo,
  FaceBookLogo,
  InstagramLogo,
  TwitterLogo,
  WPTFDarkLogo,
  MobileIcon,
  MapIcon,
  EmailIcon,
  GooglePlayIcon,
  AppStoreIcon,
} from "../../assets";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterTopContent>
        <div className="footer-logo">
          <img src={WPTFDarkLogo} alt="" />
        </div>
        <div className="footer-pages">
          <h4 className="footer-pages-header">
            Pages
           </h4>
          <h4 className="footer-pages-name">
            About Us
          </h4>
          <h4 className="footer-pages-name">
            Become Responder
          </h4>
          <h4 className="footer-pages-name">
            Contact Us
          </h4>
          <h4 className="footer-pages-name">
            Privacy Policy
          </h4>
        </div>
        <div className="footer-constants">
        <h4 className="footer-constants-header">
            Contacts
           </h4>
          <div className="footer-constants-name">
            <img src={MobileIcon} alt="" />0 801 902 380
          </div>
          <div className="footer-constants-name">
            <img src={MapIcon} alt="" />
            174 Bld Saint Germain 75006 Paris
          </div>
          <div className="footer-constants-name">
            <img src={EmailIcon} alt="" />
            weprotectsupport@wp.com
          </div>
        </div>
        <div className="footer-client">
          <div className="client-app">
          <h4 className="meta">
            Download the <span>client</span> app:
              </h4>
            <div className="client-app-logos">
              <img src={GooglePlayIcon} alt="" />
              <img src={AppStoreIcon} alt="" />
            </div>
          </div>
          <div className="client-app">
            <h4 className="meta">
            Download the <span>responder</span> app:
              </h4>
            <div className="client-app-logos">
              <img src={GooglePlayIcon} alt="" />
              <img src={AppStoreIcon} alt="" />
            </div>
          </div>
        </div>
      </FooterTopContent>
      <FooterBottomContent>
        <div className="brand-icon">
          <span>
            <img src={LinkedInLogo} alt="" />
          </span>
          <span>
            <img src={InstagramLogo} alt="" />
          </span>
          <span>
            <img src={FaceBookLogo} alt="" />
          </span>
          <span>
            <img src={TwitterLogo} alt="" />
          </span>
        </div>
        <div className="brand-brand-name">
          © 2023 &nbsp;
          All rights reserved.
        </div>
      </FooterBottomContent>
    </FooterContainer>
  );
};

export default Footer;
