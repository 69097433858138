import styled from "styled-components";
// import DatePicker from "react-datepicker";
import DatePicker from "react-date-picker";

export const MyDatePickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "-webkit-fill-available")};
  height: 40px;
  gap: 2px;
  align-items: start;
  flex: 1 1 0;
  label {
    font-family: "human_sans_400";
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    color: ${(props) => (props.value ? "#91afd7" : "#14283c")};
  }
  position: relative;

  .date-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    border-top: 1px solid #c8dcfa40;
    padding-top: 20px;
    button {
      background: #F0F5FA;
      padding: 11px 50px;
      border-radius: 12px;

      &:disabled {
        opacity: 0.5;
      }
    }

    .active-btn{
      background-color: #056DFF;
      color:#fff;
    }
  }
`;

// export const StyledInput = styled.input`
//   width: 100%;
//   background-color: #ffffff;
//   box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
//   border-radius: 12px;
//   height: 44px !important;
//   outline: unset;
// `;

export const StyledDatePicker = styled(DatePicker)`
  width: 100%;
  height:40px;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px rgba(210, 220, 240, 0.4);
  padding: 9px 10px;
  outline: unset !important;
  border: unset !important;
  font-family: "human_sans_400";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #14283c;
  .react-date-picker__calendar {
    @media (max-width: 350px) {
      position: absolute !important;
      inset: 52px 0 0 0 !important;
      width: 298px !important;
    }
    @media (max-width: 300px) {
      position: absolute !important;
      inset: 52px 0 0 0 !important;
      width: 250px !important;
    }
  }

  .react-date-picker__inputGroup__input:invalid{
      background-color: transparent;
    }

    .react-date-picker__inputGroup__input{
        outline: transparent;
    }

  .react-date-picker__calendar{
    z-index:99;
  }
  
  .react-calendar {
    width: 400px;
    overflow-x: hidden;
    border-radius: 20px;
    padding: 16px 20px;
    border: unset;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
    .react-calendar__navigation {
      .react-calendar__navigation__label {
        background-color: #ffffff;
        span {
          font-family: human_sans_700_bold;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #056dff;
          background-color: #ffffff;
        }
        :hover {
          background-color: #ffffff;
        }
      }
      .react-calendar__navigation__arrow {
        &.react-calendar__navigation__prev2-button {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.384px;
          color: #056dff !important;
          display: none;
          :hover {
            background-color: #ffffff;
          }
        }
      }
      .react-calendar__navigation__arrow {
        &.react-calendar__navigation__prev-button {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.384px;
          color: #056dff !important;
          
          :hover {
            background-color: #ffffff;
          }
        }
      }
      .react-calendar__navigation__arrow {
        &.react-calendar__navigation__next-button {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.384px;
          color: #056dff !important;
          background-color: #ffffff;
          :hover {
            background-color: #ffffff;
          }
        }
      }
      .react-calendar__navigation__arrow {
        &.react-calendar__navigation__next2-button {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          letter-spacing: -0.384px;
          color: #056dff !important;
          background-color: #ffffff;
          display: none;
          :hover {
            background-color: #ffffff;
          }
        }
      }
    }
    .react-calendar__month-view {
      .react-calendar__month-view__weekdays {
        .react-calendar__month-view__weekdays__weekday {
          abbr {
            color: #14283c !important;
            font-family: human_sans_400_bold;
            font-style: normal;
            font-size: 14px;
            line-height: 20px;
            cursor: auto;
            text-decoration: none;
          }
        }
      }
      .react-calendar__month-view__days__day--neighboringMonth {
        width: 50px;
        height: 40px;
        font-family: human_sans_400_bold !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px;
        letter-spacing: 0.002em;
        color: #c8dcfa !important;
      }
      .react-calendar__tile .react-calendar__month-view__days__day {
        width: 50px;
        height: 40px;
        font-family: human_sans_400_bold !important;
        font-style: normal;
        font-weight: 400 !important;
        font-size: 14px !important;
        line-height: 20px;
        letter-spacing: 0.002em;
        color: #14283c !important;
      }
      .react-calendar__tile--now {
        width: 50px;
        height: 40px;
        font-family: human_sans_700_bold !important;
        font-style: normal;
        font-weight: 700 !important;
        font-size: 16px;
        line-height: 24px;
        color: #056dff !important;
        background-color: #ffffff;
        opacity: 0.7;
      }
      .react-calendar__month-view__days__day {
        font-family: human_sans_400_bold;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.002em;
        color: #14283c;
        background-color: #ffffff;
      }
      .react-calendar__tile--active {
        width: 40px;
        height: 40px;
        font-family: human_sans_400_bold !important;
        font-style: normal;
        font-weight: 500 !important;
        font-size: 14px;
        /* line-height: 24px; */
        color: #fff !important;
        background-color: #056DFF;
        border-radius: 50%;
      }
    }
    .react-calendar__viewContainer {
      .react-calendar__year-view {
        .react-calendar__year-view__months {
          gap: 12px;
          .react-calendar__tile--now {
            background: #c8dcfa8a !important;
            mix-blend-mode: normal !important;
            border-radius: 12px !important;
          }
        }
        button {
          flex: unset !important;
          background: #c8dcfa40;
          mix-blend-mode: normal;
          border-radius: 12px;
          height: 32px;
          width: 149px;
          border-radius: 12px;
          padding: unset;
          font-family: human_sans_400;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #5573a0;
          &.react-calendar__tile--now {
            font-family: human_sans_400_bold;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #056dff;
          }
        }
      }
    }
    .react-calendar__viewContainer {
      .react-calendar__decade-view {
        .react-calendar__decade-view__years {
          gap: 12px;
          .react-calendar__tile--now {
            background: #c8dcfa8a !important;
            mix-blend-mode: normal !important;
            border-radius: 12px !important;
          }
        }
        button {
          flex: unset !important;
          background: #c8dcfa40;
          mix-blend-mode: normal;
          border-radius: 12px;
          height: 32px;
          width: 149px;
          border-radius: 12px;
          padding: unset;
          font-family: human_sans_400;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #5573a0;
          &.react-calendar__tile--now {
            font-family: human_sans_400_bold;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #056dff;
          }
        }
      }
    }
    .react-calendar__viewContainer {
      .react-calendar__century-view {
        .react-calendar__century-view__decades {
          gap: 12px;
          .react-calendar__tile--now {
            background: #c8dcfa8a !important;
            mix-blend-mode: normal !important;
            border-radius: 12px !important;
          }
        }
        button {
          flex: unset !important;
          background: #c8dcfa40;
          mix-blend-mode: normal;
          border-radius: 12px;
          height: 32px;
          width: 149px;
          border-radius: 12px;
          padding: unset;
          font-family: human_sans_400;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #5573a0;
          &.react-calendar__tile--now {
            font-family: human_sans_400_bold;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #056dff;
          }
        }
      }
    }
    @media (max-width: 355px) {
      width: 233px !important;
      max-width: 233px !important;
    }
    @media (max-width: 677px) {
      width: 100% !important;
      max-width: 100% !important;
    }
    @media (max-width: 768px) {
      width: auto;
      max-width: auto;
    }
    @media (max-width: 1000px) {
      width: auto;
      max-width: auto;
    }
  }
  .react-date-picker__wrapper {
    border: unset;
    border-color: unset;
  }
  input {
    :focus-visible {
      outline: unset;
    }
  }
  .react-date-picker__inputGroup {
    padding: unset;
  }
  .react-date-picker__calendar-button {
    // padding-right: 13px;
    /* display: none; */
  }
  .react-date-picker__clear-button {
    display: none;
  }
  ::placeholder {
    line-height: 24px;
    color: #91afd7;
    font-family: "human_sans_400";
    font-style: normal;
    font-size: 16px;
  }
`;
