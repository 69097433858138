import React, {useEffect} from 'react'
import {Nav} from './style'
import Logo from '../../assets/logo.png'

const Index = () => {

 useEffect(() => {
    const navbar = document.querySelector('.nav');
    window.onscroll = () => {
      if (window.scrollY > 180) {
        navbar.setAttribute(
          "style", "-webkit-backdrop-filter: blur(8px); backdrop-filter: blur(8px);");
      } else {
        navbar.removeAttribute("style");
      }
    };

  }, [])




  return (
    <Nav className='nav'>
       <img src={Logo} alt="" width={127} height={30} />
    </Nav>
  )
}

export default Index