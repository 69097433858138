import React from 'react'
import {InputWrapper} from './style'

const index = ({
    type,
    value,
    onChange=() => {},
    placeholder,
    name,
    label
}) => {
  return (
   <InputWrapper>
   <label htmlFor="Email">{label}</label>
   <input type="text" name={name} placeholder={placeholder} value={value} onChange={onChange} />
   </InputWrapper>
  )
}

export default index