import './App.css';
import { useState, useEffect } from 'react';
import Home from './pages/Home'
import MyNotification from './components/MyNotification';

function App() {
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    if (notification) {
      setTimeout(() => setNotification(null), 2 * 1000);
    }
  }, [notification]);



  return (
    <>
      {notification && (
        <MyNotification
          message={notification?.message ? notification.message : ""}
          table={notification?.table ? notification.table : ""}
          backGroundColor={
            notification?.backGroundColor ? notification.backGroundColor : ""
          }
          icon={notification?.icon ? notification.icon : ""}
          onClick={() => setNotification(null)}
        />
      )}
      <Home setNotification={setNotification} />
    </>
  );
}

export default App;
