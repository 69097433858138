import styled from "styled-components";

export const Nav = styled.nav`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 160px;
    height: 100px;
    background-color: #000;
    color: #333;
    background: transparent;
    z-index: 99;
    

    @media (max-width: 768px) {
    padding: 0px 20px;
  }
  @media (max-width: 1000px) {
    padding: 0px 20px;
  }

    `