import React from "react";
import { MyDatePickerContainer, StyledDatePicker } from "./style";
import "react-datepicker/dist/react-datepicker.css";
import 'react-date-picker/dist/DatePicker.css';
import { createPortal } from "react-dom";
import { FaCalendar } from "react-icons/fa";

const MyDatePicker = ({
  label = "",
  value = "",
  onChange = () => {},
  className = "",
  width = "",
  placeholder = "",
}) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <MyDatePickerContainer width={width} value={value !== ""}>
      {label && <label>{label}</label>}
      <StyledDatePicker
        value={value}
        onChange={onChange}
        onCalendarOpen={() => {
          setIsOpen(true);
        }}
        className={className}
        format="MM/dd/yyyy"
        placeholderText={placeholder}
        maxDate={new Date()}
        monthPlaceholder="MM"
        dayPlaceholder="JJ"
        yearPlaceholder="AAAA"
        showLeadingZeros={true}
        closeCalendar={false}
        isOpen={isOpen}
        calendarIcon={<FaCalendar style={{fontWeight:900, color:'#A0C8FA'}} />}
      ></StyledDatePicker>
      {isOpen &&
        createPortal(
          <div className="date-buttons">
            <button onClick={() => setIsOpen(false)}>Cancel</button>
            <button className="active-btn" disabled={!value} onClick={() => setIsOpen(false)}>Apply</button>
          </div>,
          document.querySelector(".react-calendar")
        )}
    </MyDatePickerContainer>
  );
};

export default MyDatePicker;
