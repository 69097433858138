import React, { useState } from 'react'
import { HomeWrapper, StyledPhoneInput } from './style'
import Input from '../../components/Input'
import MyDatePicker from '../../components/MyDatePicker'
import { parsePhoneNumber, formatPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {BsPatchCheckFill, BsPatchExclamationFill} from 'react-icons/bs'
import Layout from '../../components/layout'
import axios from 'axios';
import moment from 'moment';

const Index = ({setNotification}) => {
  const [firstName, setFirstName] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [date, setDate] = useState('')
  const [phone, setPhone] = useState('')
  const [countryCode, setCountryCode] = useState('33')
  const [postalCode, setPostalCode] = useState('')

// useEffect(() => {
//   if (phone) {
//     let phoneNumber = formatPhoneNumber(phone);
//     if (phoneNumber.length > 0 && phoneNumber.length <= 9) {
//       setPhone(phone);
//     }
//   }
// }, [countryCode])



const onSend = () => {
  if(email && RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/).test(email)){
  const formData = new FormData();
  formData.append('first_name', firstName);
  formData.append('full_name', name);
  formData.append('dob',moment(date).format('MM/DD/YYYY'));
  formData.append('phone', phone);
  formData.append('email', email);
  formData.append('zipcode', postalCode);

axios.post(`https://wptf-landing.ozandac.com/sheet.php`, formData).then((response) => {
  setNotification({
    message: 'Thank you! We will contact with you!',
    table: ``,
    backGroundColor: `#34CD77`,
    icon: <BsPatchCheckFill color="white" size={20} />,
  });
  setFirstName('')
  setName('')
  setEmail('')
  setDate('')
  setPhone('')
  setCountryCode('33')
  setPostalCode('')
}).catch((error) => {
  setNotification({
    message: 'Something Went Wrong',
    table: ``,
    backGroundColor: `#DC1E00`,
    icon: <BsPatchExclamationFill color="white" size={20} />,
  });
});
  }else{
    setNotification({
      message: 'Please enter a valid email',
      table: ``,
      backGroundColor: `#DC1E00`,
      icon: <BsPatchExclamationFill color="white" size={20} />,
    });
  }

}


  return (
    <Layout>
    <HomeWrapper>
      <h1>POSTULEZ POUR DEVENIR L'UN DE NOS <span>"AMBASSADEURS" WEPROTECT</span></h1>
      <div className="form-container">
        <Input value={firstName} label={'Prénom'} name={'firstName'} placeholder={'Entrez votre prénom'} onChange={(e) => setFirstName(e.target.value)} />
        <Input value={name} label={'Nom'} name={'name'} placeholder={'Entrez votre nom'} onChange={(e) => setName(e.target.value)} />
        <div className="phone-number">
        <label htmlFor="date">Date de naissance</label>
        <MyDatePicker 
        value={date}
        className="input-item"
        onChange={(date) => {
          setDate(date);
        }}
        width={'100%'}
        placeholder={'MM/JJ/AAAA'}
        />
        </div>
        <div className="phone-number">
          <label htmlFor="phone">Numéro de téléphone</label>
          <StyledPhoneInput
            placeholder={'Numéro de téléphone'}
            international
            value={phone}
            defaultCountry="FR"
            limitMaxLength={true}
            onChange={(value) => {
              // if (value) {
              //   let parsePhoneNumberValue = parsePhoneNumber(value);
              //   setCountryCode(parsePhoneNumberValue?.countryCallingCode);
              // }
              // let phoneNumber = formatPhoneNumber(value);
              setPhone(value);
            }}
            countryCallingCodeEditable={false}
          />
          </div>
        <Input value={email} label={'Email'} name={'email'} placeholder={'Entrez votre e-mail'} onChange={(e) => setEmail(e.target.value)} />
        <Input value={postalCode} label={'Code postal de residence'} name={'postalCode'} placeholder={'Entrez votre code postal de residence'} onChange={(e) => setPostalCode(e.target.value.replace(/[^0-9]/g, ''))} />
        <button className='btn' disabled={!firstName || !name || !email || !date || !phone || !postalCode} onClick={onSend}>Rejoignez-nous</button>
      </div>
    </HomeWrapper>
    </Layout>

  )
}

export default Index